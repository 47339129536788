import React, { useState, Fragment } from 'react'
import clsx from 'clsx'
// Material UI Components
import Input from '@material-ui/core/Input'
import FilledInput from '@material-ui/core/FilledInput'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Tooltip from '@material-ui/core/Tooltip'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { withStyles } from '@material-ui/core/styles'
// Material UI Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
// Custom Imports
import SunValidator from '@sunsh1n3/sun-input-validation'
// Styles
import SunValidInputStyles from './styles'

//#region Styled MUI Components
const StyledInput = withStyles({
  root: {
    // Compensating for MuiFilledInput & MuiOutlinedInput -adornedEnd having padding
    paddingRight: '14px',
    '&:hover': {
      backgroundColor: 'rgba(200, 200, 200, 1)'
    }
  },
  input: {
    padding: '2rem'
  }
})(Input)

const StyledFilledInput = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(200, 200, 200, 1)'
    }
  },
  input: {
    padding: '2rem'
  }
})(FilledInput)

const StyledOutlinedInput = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(200, 200, 200, 1)'
    }
  },
  input: {
    padding: '2rem'
  },
  notchedOutline: {
    display: 'none'
  }
})(OutlinedInput)

const ErrorTooltip = withStyles({
  tooltip: {
    backgroundColor: '#d32f2f',
    color: '#fff'
  },
  arrow: {
    color: '#d32f2f'
  }
})(Tooltip)

const VisibilityTooltip = withStyles({
  tooltip: {
    backgroundColor: '#0099ff',
    color: '#fefefe',
    fontSize: '1.5rem'
  },
  arrow: {
    color: '#0099ff'
  }
})(Tooltip)

const VisibilityToggle = withStyles({
  root: {
    border: 0,
    backgroundColor: 'transparent',
    padding: '0',
    '&.Mui-selected': {
      backgroundColor: 'transparent'
    }
  },
  selected: {
    backgroundColor: 'transparent'
  },
  disabled: {
    backgroundColor: 'transparent'
  }
})(ToggleButton)
//#endregion

// based off Material UI TextField implementation
const variantComponent = {
  standard: {
    input: StyledInput,
    visibilityIcon: VisibilityIcon,
    visibilityOffIcon: VisibilityOffIcon
  },
  filled: {
    input: StyledFilledInput,
    visibilityIcon: VisibilityIcon,
    visibilityOffIcon: VisibilityOffIcon
  },
  outlined: {
    input: StyledOutlinedInput,
    visibilityIcon: VisibilityOutlinedIcon,
    visibilityOffIcon: VisibilityOffOutlinedIcon
  }
}

export default function SunValidatedInput(props) {
  // Modified from Material UI TextField implementation
  const {
    autoComplete,
    autoFocus = false,
    children,
    classes,
    className = '',
    color = 'primary',
    defaultValue,
    disabled = false,
    error = false,
    errorFunc = null,
    errorIcon = null,
    errorText = 'Invalid input',
    errorTooltipOnlyOnFocus = false,
    errorTooltipPosition = 'right',
    errorPopperProps,
    showErrorArrow = true,
    showErrorIcon = true,
    showErrorTooltip = false,
    FormHelperTextProps,
    fullWidth = true,
    helperText,
    hiddenLabel,
    id = '',
    InputLabelProps,
    inputProps,
    InputProps,
    inputRef,
    label,
    multiline = false,
    name = '',
    onBlur,
    onChange,
    onFocus,
    overrideValidation = false,
    placeholder = '',
    required = false,
    rows,
    rowsMax,
    select = false,
    SelectProps,
    variant = 'filled',
    valid = false,
    validIcon = null,
    showValidIcon = false,
    value = '',
    visibilityIconVariant = 'standard',
    ...other
  } = props

  /*
    Split type & init type because type needs to change in order
    to toggle password visibility
  */
  const initType = props.type || null
  const [type, setType] = useState(props.type || 'text')
  const [localVal, setLocalVal] = useState(props.value || '')
  const [localErr, setLocalErr] = useState(props.error || false)
  const [localValid, setLocalValid] = useState(props.valid || false)
  const [otherProps, setOtherProps] = useState(other || {})
  const [validCssClass, setValidCssClass] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [errorTooltipVisible, setErrorTooltipVisible] = useState(true)
  const [hidPassword, setHidPassword] = useState(false)

  const handleChange = (e) => {
    if (onChange !== null && onChange !== undefined) {
      onChange(e)
    }
    setLocalVal(e.target.value)
    validate(e)
  }

  const handleBlur = (e) => {
    if (onBlur !== null && onBlur !== undefined) {
      onBlur(e)
    }
    validate(e)
    if (errorTooltipOnlyOnFocus) {
      setErrorTooltipVisible(false)
    }
    if (localValid && showPassword && !hidPassword && initType === 'password') {
      toggleVisibility()
      setHidPassword(true)
    }
  }

  const handleFocus = (e) => {
    if (onFocus !== null && onFocus !== undefined) {
      onFocus(e)
    }
    if (errorTooltipOnlyOnFocus) {
      setErrorTooltipVisible(true)
    }
  }

  const toggleVisibility = (e) => {
    type === 'password' ? setType('text') : setType('password')
    setShowPassword(!showPassword)
  }

  const getErrorState = () => {
    return localErr || (props.error && overrideValidation)
  }

  const validate = (e) => {
    const notEmpty = e.target.value !== ''
    if (notEmpty) {
      updateValidCssClass('add', 'not-empty')
    }
    if (!overrideValidation) {
      // No validation override
      if (initType === 'password') {
        const isValidPassword = SunValidator.isValidPasswordEvent(e)
        setLocalErr(!isValidPassword && notEmpty)
        setLocalValid(isValidPassword && notEmpty)
        if (isValidPassword && notEmpty) {
          updateValidCssClass('add', 'valid')
          updateValidCssClass('add', 'not-empty')
        }
      } else if (initType === 'email') {
        const isValidEmail = SunValidator.isValidEmailEvent(e)
        setLocalErr(!isValidEmail && notEmpty)
        setLocalValid(isValidEmail && notEmpty)
        if (isValidEmail && notEmpty) {
          updateValidCssClass('add', 'valid')
          updateValidCssClass('add', 'not-empty')
        }
      }
    } else {
      // Validation override
      if (errorFunc !== null) {
        setLocalErr(errorFunc(e))
      } else if (
        props.error !== null &&
        props.error !== undefined &&
        localErr !== props.error
      ) {
        setLocalErr(props.error)
      }
      if (
        props.valid !== null &&
        props.valid !== undefined &&
        localValid !== props.valid
      ) {
        setLocalValid(props.valid)
      }
    }
    if (e.target.value === '' && validCssClass.includes('not-empty')) {
      updateValidCssClass('remove', 'not-empty')
    }
  }

  const updateValidCssClass = (updateType, newValue) => {
    let newCssClass = validCssClass
    switch (updateType) {
      case 'add':
        if (!newCssClass.includes(newValue)) {
          newCssClass += ' ' + newValue
        }
        break
      case 'remove':
        if (!newCssClass.includes(newValue)) {
          newCssClass = newCssClass.replaceAll(newValue, '')
        }
        break
      default:
        newCssClass = validCssClass
        break
    }
    // Removing excess whitespace
    newCssClass = newCssClass.replace(/  +/g, ' ')
    setValidCssClass(newCssClass)
  }

  const InputComponent = variantComponent[variant].input || StyledFilledInput
  // Adding disableUnderline if component used is not OutlinedInput
  //    - throws a warning otherwise since disableUnderline is not
  //      a prop of OutlinedInput
  if (variant !== 'outlined' && !('disableUnderline' in otherProps)) {
    setOtherProps({
      disableUnderline: props.disableUnderline || true,
      ...otherProps
    })
  }
  // Removing type from 'extra' props
  //    - password hiding/showing doesn't work otherwise
  if ('type' in otherProps) {
    let newOtherProps = {}
    for (const key in otherProps) {
      if (key !== 'type') {
        newOtherProps[key] = otherProps[key]
      }
    }
    setOtherProps(newOtherProps)
  }

  const VisibilityComponent =
    variantComponent[visibilityIconVariant].visibilityIcon || VisibilityIcon
  const VisibilityOffComponent =
    variantComponent[visibilityIconVariant].visibilityOffIcon ||
    VisibilityOffIcon

  const pgStyles = SunValidInputStyles({ showTooltip: errorTooltipVisible })

  return (
    <InputComponent
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      className={clsx(
        className,
        classes ? classes.root : 'default-styles',
        pgStyles.wrapperInput,
        validCssClass
      )}
      defaultValue={defaultValue}
      endAdornment={
        <>
          {initType === 'password' ? (
            <VisibilityTooltip
              className={pgStyles.showPasswordTooltip}
              title={showPassword ? 'Hide password' : 'Show password'}
              placement='top-end'
              key={showPassword}
            >
              <VisibilityToggle
                value={showPassword}
                selected={showPassword}
                onChange={toggleVisibility}
                disableRipple
              >
                {localVal !== '' ? (
                  showPassword ? (
                    <VisibilityComponent className='icon eye' color='primary' />
                  ) : (
                    <VisibilityOffComponent
                      className='icon eye'
                      color='primary'
                    />
                  )
                ) : (
                  <div className={pgStyles.nothingDiv}></div>
                )}
              </VisibilityToggle>
            </VisibilityTooltip>
          ) : (
            <div className={pgStyles.nothingDiv}></div>
          )}
          {showErrorTooltip ? (
            getErrorState() ? (
              <ErrorTooltip
                className={
                  'error-tooltip' + (errorTooltipVisible ? '' : ' hidden')
                }
                id='error-tooltip'
                title={errorText}
                placement={errorTooltipPosition}
                arrow={showErrorArrow}
                open={localErr}
                PopperProps={errorPopperProps}
              >
                {showErrorIcon ? (
                  errorIcon || <CancelIcon className='icon err' color='error' />
                ) : (
                  <div style={{ margin: 0, padding: 0 }}></div>
                )}
              </ErrorTooltip>
            ) : (
              <div className={pgStyles.nothingDiv}></div>
            )
          ) : (
            <div className={pgStyles.nothingDiv}></div>
          )}
          {showValidIcon && value !== '' ? (
            localValid ? (
              <>
                {validIcon || (
                  <CheckCircleIcon className='icon valid' htmlColor='#00d800' />
                )}
              </>
            ) : (
              <div className={pgStyles.nothingDiv}></div>
            )
          ) : (
            <div className={pgStyles.nothingDiv}></div>
          )}
        </>
      }
      error={getErrorState()}
      fullWidth={fullWidth}
      id={id}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      placeholder={placeholder}
      required={required}
      type={type}
      value={value !== '' ? value : localVal}
      {...otherProps}
    />
  )
}
