import { makeStyles } from '@material-ui/styles'

const SunValidInputStyles = makeStyles({
  wrapperInput: {
    '&.default-styles': {
      backgroundColor: '#f6f6f6',
      border: '2px solid transparent'
    },
    '&.Mui-error': {
      border: '2px solid red'
    },
    '&.valid.not-empty': {
      border: '2px solid #00d800'
    },
    '&.Mui-focused': {
      border: '2px solid #0099ff',
      background: '#f6f6f6'
    },
    '& .icon': {
      fontSize: '2rem',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0)'
      }
    },
    '& #error-tooltip': {
      visibility: (props) => (props.showTooltip ? 'visible' : 'hidden')
    },
    '& .MuiToggleButton-root.Mui-selected:hover': {
      backgroundColor: 'rgba(0,0,0,0)'
    }
  },
  nothingDiv: {
    display: 'none'
  }
})

export default SunValidInputStyles
